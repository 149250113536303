import { ClassProvider } from '@angular/core';

import { Types } from '../../../domain/types.domain';
import { RefuseSignatureService } from '../../../domain/signatures/services/refuse-signature.service';

export class RefuseSignatureServiceInstruction implements ClassProvider {
  provide = Types.RefuseSignatureService;
  deps = [Types.SignatureRepository];
  useClass = RefuseSignatureService;
}
