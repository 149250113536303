import { ClassProvider } from '@angular/core';

import { GetHistoryByDocumentCodeService } from '../../../domain/history/services/get-history-by-document-code.service';
import { Types } from '../../../domain/types.domain';

export class GetHistoryByDocumentCodeServiceInstruction implements ClassProvider {
  provide = Types.GetHistoryByDocumentCode;
  deps = [Types.Settings];
  useClass = GetHistoryByDocumentCodeService;
}
