import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { lastValueFrom } from 'rxjs';

import { CreateSignatureInput } from '../../signatures/dtos/create-signature.input';
import { SignatureType } from '../../signatures/enums/signature-type';
import { CreateSignatureServiceInterface } from '../../signatures/interfaces/create-signature.service.interface';
import { Types } from '../../types.domain';
import { GetCurrentUserServiceInterface } from '../../user/interfaces/services/get-current-user.service.interface';
import { FileConvertionsUtils } from '../../utils/file-convertions.utils';
import { UploadDocumentInput } from '../dtos/upload-document.input';
import { UploadDocumentOutput } from '../dtos/upload-document.output';
import { BranchCodeEnum } from '../enums/branch-code.enum';
import { DocumentRepositoryInterface } from '../interfaces/repositories/document.repository.interface';
import { UploadDocumentServiceInterface } from '../interfaces/services/upload-document.service.interface';

@Injectable()
export class UploadDocumentService implements UploadDocumentServiceInterface {
  private readonly _http = inject(HttpClient);
  private readonly _userService = inject<GetCurrentUserServiceInterface>(Types.GetCurrentUserService);
  private readonly _documentRepository = inject<DocumentRepositoryInterface>(Types.DocumentRepository);
  private readonly _createSignatureService = inject<CreateSignatureServiceInterface>(Types.CreateSignature);

  async handle(input: UploadDocumentInput): Promise<UploadDocumentOutput> {
    const [uploadRequest, documentFile] = await Promise.all([
      this._documentRepository.prepareBranchUpload(input.document, BranchCodeEnum.PADES_VERSION),
      this.getDocumentFile(input),
    ]);

    await lastValueFrom(
      this._http.put(uploadRequest.uploadOperationUrl, documentFile, {
        headers: { excludeInterceptors: 'true' },
      })
    );

    const user = await this._userService.handle();
    const signature: CreateSignatureInput = {
      userId: user.sub,
      userName: user.name,
      documentCode: input.document.code,
      signatureType: SignatureType.ICP,
    };

    await this._createSignatureService.handle(signature);
    return new UploadDocumentOutput({
      updatedRemoteDocumentUrl: input.document.downloadUrl,
    });
  }

  private async getDocumentFile(input: UploadDocumentInput): Promise<File> {
    const filename = input.document.filename;
    const base64UrlPrefix = 'data:application/pdf;base64,';
    if (input.signedDocumentUrl.startsWith(base64UrlPrefix)) {
      const signedDocumentBase64 = input.signedDocumentUrl.replace(base64UrlPrefix, '');
      return FileConvertionsUtils.base64ToFile(filename, signedDocumentBase64);
    }

    const documentBuffer = await lastValueFrom(
      this._http.get(input.signedDocumentUrl, {
        responseType: 'arraybuffer',
        headers: { excludeInterceptors: 'true' },
      })
    );
    return FileConvertionsUtils.arrayBufferToFile(filename, documentBuffer);
  }
}
