import { ClassProvider } from '@angular/core';

import { Types } from '../../../domain/types.domain';
import { SignaturesListService } from '../../../app/shared/services/signatures/signature-list.service';

export class SignaturesListServiceInstruction implements ClassProvider {
  provide = Types.SignaturesListService;
  deps = [];
  useClass = SignaturesListService;
}
