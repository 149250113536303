import { Injectable, inject } from '@angular/core';

import { Types } from '../../types.domain';
import { RefuseSignatureServiceInterface } from '../interfaces/refuse-signature.service.interface';
import { SignatureRepositoryInterface } from '../interfaces/repositories/signature.repository.interface';
import { RefuseSignatureInput } from '../dtos/refuse-signature.input';

@Injectable()
export class RefuseSignatureService implements RefuseSignatureServiceInterface {
  private readonly _signatureRepository = inject<SignatureRepositoryInterface>(Types.SignatureRepository);

  async handle(input: RefuseSignatureInput): Promise<void> {
    await this._signatureRepository.refuse(input);
  }
}
