import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { SignatureOutput } from '../../../../domain/signatures/dtos/get-documents-signatures.output';

@Injectable({
  providedIn: 'root',
})
export class SignaturesListService {
  private readonly signaturesSubject = new BehaviorSubject<SignatureOutput[]>([]);
  signatures$ = this.signaturesSubject.asObservable();

  setSignaturesList(signatures: SignatureOutput[]): void {
    this.signaturesSubject.next(signatures);
  }

  getSignaturesList(): Observable<SignatureOutput[]> {
    return this.signatures$;
  }
}
