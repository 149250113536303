import { Injectable, inject } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Types } from '../../types.domain';
import { History } from '../entities/history';
import { GetHistoryByDocumentCodeServiceInterface } from '../interfaces/get-history-by-document-code.service.interface';
import { GetHistoryByDocumentCodeInput } from '../dtos/get-history-by-document-code.input';

@Injectable()
export class GetHistoryByDocumentCodeService implements GetHistoryByDocumentCodeServiceInterface {
  private readonly _http = inject(HttpClient);
  private readonly _settings = inject(Types.Settings);

  async getHistoryByDocumentCode(input: GetHistoryByDocumentCodeInput): Promise<History[]> {
    const requestUrl = `${this._settings.signManagerUrl}/history/v1/document/${input.documentCode}`;
    return lastValueFrom(this._http.get<History[]>(requestUrl));
  }
}
